import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Powder Metallurgy",
  "description": "For making metals with more desirable qualities.",
  "author": "Orkun Zafer Ozturk",
  "categories": ["mechanical"],
  "date": "2022-01-01T00:00:00.000Z",
  "featured": false,
  "tags": ["", "", "", "", ""],
  "relatedArticles": ["", ""]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Powder metallurgy is the process of mixing metal and alloy powders together to form a material with more desirable qualities. This is done at just below the melting temperatures of the input materials under pressure, akin to injection molding or die casting. Powder metallurgy can generate consistent parts with very complex shapes. But only small parts can be done with powder metallurgy. The weight of a part made with powder metallurgy is usually restricted to 200 - 250 grams.  `}</p>
    <p>{`Compared to die casting, you can utilize a larger selection of materials in powder metallurgy, including magnetic materials. It’s easier to avoid surface imperfections with powder metallurgy than with die casting.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      